

.innovation-header {
  text-align: center;
  background-color: #f0ce89;
  padding: 20px;
  color: black;
  font-weight: 500;
  font-size: 25px;
  font-family: "auto";
}

.innovation-title {
  text-align: center;
  color: white;
  font-weight: 500;
  margin-bottom: 50px;
}

.innvocation {
  display: flex;
  gap: 25px;
  margin-bottom: 20px;
}

.hero-text {
  color: white;
  font-size: 35px;
  line-height: 45px;
  font-weight: bold;
  text-align: center;
}

.hero-inner {
  color: white;
  font-size: 25px;
  font-weight: 200;
  text-align: center;
}

.innovation-about-text {
  font-size: 12px;
}

.innovation {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.section {
  display: flex;
  flex-direction: row;
  align-items: self-start;
  margin-bottom: 40px;
}

.innovation-image,
.innovation-section {
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
}

.innovation-image img {
  width: 100%;
  height: auto;
}

.innovation-section h6 {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #000000;
  font-weight: 400;
}

.innovation-about-text {
  font-size: 0.9em;
  line-height: 1.8;
  color: #5c5a5a;
  font-weight: 400;
}

.Banner-section-title {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
  color: #d2a742;
  font-size: 35px;
  font-weight: 700;
}

/* Media Query for Mobile Screens */
@media (max-width: 768px) {
  .section {
    flex-direction: column;
  }

  .innovation-image {
    order: 1;
  }

  .innovation-section {
    order: 2;
  }
}

.product-container {
  text-align: center;
}
.product-image {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin: 0 auto;
}
.product-title {
  font-size: 1.2rem;
  font-weight: 400;
  color: #000;
  margin-top: 1rem;
}

.product-description {
  font-size: 0.8rem;
  color: #666;
  line-height: 1.8;
  font-weight: 400;
  margin-top: 0.5rem;
}

.contact {
  background-color: #000;
  color: white;
  font-family: Arial, sans-serif;
}
.contact-section {
  padding: 30px 20px;
  max-width: 1200px;
  margin: auto;
}
.contact-header {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: #d2a742;
  margin-bottom: 20px;
}
.contact-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.contact-info,
.contact-image {
  width: 100%;
  max-width: 600px;
}
.contact-info {
  margin-bottom: 20px;
}
.contact-info h3 {
  font-size: 1.1rem;
  margin-bottom: 15px;
  font-weight: 400;
  color: white;
}
.contact-info p {
  font-size: 0.8rem;
  margin-bottom: 10px;
  line-height: 1.2;
  font-weight: 500;
  text-align: center;
  color: rgb(104, 101, 101);
}
.contact-info a {
  color: #d2a742;
  text-decoration: none;
}
.contact-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}
.footer {
  text-align: center;
  margin-top: 20px;
}
.footer p {
  color: rgb(95, 95, 95);
  font-weight: 300;
  font-size: 12px;
}
.footer h6 {
  font-weight: 300;
  font-size: 1em;
  color: white;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .contact-content {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }
  .innovation-header {
    font-size: 35px;
  }
  .contact-info {
    margin-bottom: 0;
  }
  .contact-info p {
    text-align: left;
  }

  .hero-text {
    font-size: 45px;
  }

  .hero-inner {
    font-size: 35px;
  }
}
