.innvocation-banner{
  background-image:linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%), url('./assets/innovation/innovation.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.gradient-container {
  position: relative;
  display: inline-block;
}

.gradient-container img {
  display: block; /* Removes any gap under the image */
  width: 100%;
  height: auto;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%); /* Example gradient */
  pointer-events: none; /* Allows interactions with the image underneath */
}